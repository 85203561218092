* {
  max-width: 880px;
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 880px;
  border: 0.8rem black solid;
  height: auto;
  box-shadow: inset 0px 2px 10px black;
}

html {
  font-size: 62.5%;
  margin: 0 auto;
  scroll-behavior: smooth;
}

.headerImg {
  text-shadow: 0.7px 0.7px 1px #fff, -0.7px -0.7px 1px #000, 2px 2px 10px #fff;
  color: #00ffffeb;
  box-shadow: inset -1px -2px 10px black, inset 1px 2px 10px white;
  background: linear-gradient(145deg, #10ffff, #0ed8e6);
  display: flex;
  justify-content: space-evenly;
}

.headerImg img {
  max-width: 100%;
  width: 300px;
  padding: 2rem;
  margin: 2rem;
  border-radius: 115px;
  background: linear-gradient(145deg, #10ffff, #0ed8e6);
  box-shadow: 10px 10px 10px #0bb4bf, -10px -10px 10px #13ffff;
}
.navSkills {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
nav {
  width: auto;
  border: solid #0ff 1rem;
  border-radius: 5px;
  box-shadow: 0.12px 0.24px 4px black, -0.12px -0.24px 4px white;
  top: 0.9rem;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav a {
  font-size: 4rem;
  padding: 1rem;
  text-shadow: 0.5px 0.5px 1.1px #000, -0.9px -0.9px 1.1px #f9f9f9;
  color: #0ff999;
  background: #00ff7f;
  transition: text-shadow 125ms ease;
  border-radius: 5px;
  box-shadow: 0.12px 0.24px 4px black, -0.12px -0.24px 4px white;
}
nav a:hover {
  text-shadow: 0.7px 0.7px 1px #fff, -0.7px -0.7px 1px #000, 2px 2px 10px #fff;
  box-shadow: inset 0 0 4px 1px #000, inset 0 6px 7px 0 #008b8b;
}

nav a:active {
  text-shadow: 0.7px 0.7px 1px #fff, -0.7px -0.7px 1px #000, 2px 2px 10px #fff;
  box-shadow: inset 0 0 4px 1px #000, inset 0 6px 7px 0 #008b8b;
}
section#skills {
  width: 100%;
  /* border-bottom: #0ff 2rem solid; */
  font-size: 3rem;
  font-weight: 600;
  padding: 2rem;
  font-variant: unicase;
  text-shadow: -0.5px -0.5px 1.1px #000, 0.9px 0.9px 1.1px #f9f9f9;
}

h2 {
  font-size: 3rem;
}

#skills > p {
  color: darkturquoise;
}

main {
  display: block;
}

#about {
  display: flex;
  flex-direction: column;
}

#about h2 {
  font-size: 3rem;
  font-variant: unicase;
  padding-left: 30px;
  text-shadow: 1.12px 1.48px 4px #714505, -1.12px -2.48px 4px #f1d196;
  color: darkorange;
  box-shadow: inset -222px 222px 220px 218px #ff9800;
  font-size: 10rem;
}

.introduction {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 auto;
  padding: 2rem;
  text-shadow: -0.5px -0.5px 1.1px #000, 0.9px 0.9px 1.1px #f9f9f9;
  font-variant: titling-caps;
}

.introduction > p {
  margin: 1rem;
  margin-right: 4rem;
  padding: 0.1rem;
  line-height: 1.5;
  border-bottom: aqua 1rem solid;
}

section#projects {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* box-shadow: inset 0px 2px 10px black; */
  padding-top: 2rem;
}

.card {
  display: flex;
  justify-content: space-around;
  width: auto;
  border: 0.5rem #0ff999 groove;
  border-radius: 1%;
  background: #0ff;
  color: #00ffffeb;
  text-shadow: 1px 1px 0.1px #000, -1px -1px 0.1px #fff;
  flex-direction: column;
  font-weight: 700;
  max-width: 64%;
  max-height: 550px;
  height: auto;
  margin: 1rem 0;
}
.card img {
  border-bottom: #fff 0.8rem groove;
  border-radius: 5px;
  box-shadow: none;
  text-align: center;
  width: -webkit-fill-available;
  height: 400px;
}

.card h3 {
  font-size: 2rem;
  font-size: 5rem;
  border-bottom: 0.5rem groove #0ff999;
  padding: 0.5rem;
  margin: 0;
  text-align: center;
  box-shadow: 0 0 #000;
}

.projectInfoBlock {
  text-align: center;
  height: 5rem;
  display: flex;
}
.projectInfoBlock a {
  font-size: 1.6rem;
  font-weight: bolder;
  text-decoration: none;
  width: -webkit-fill-available;
  border-radius: 8px;
  padding: 0.5rem;
  margin-top: 1rem;
  color: #faebd7;
  text-shadow: 2px 0 0.1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectInfoBlock a:first-child {
  border-right: #0ff999 solid 1.5px;
  border-left: #09a565 solid 1px;
  box-shadow: inset 0 0 7px 0 #000, 0.1px 0.2px 2px 1.1px #023;
  font-weight: 500;
  text-shadow: 0.5px 0.5px 1.1px #000, -0.9px -0.9px 1.1px #f9f9f9;
  color: #0ff;
  transition: text-shadow 250ms ease, transform 1s ease;
}
.projectInfoBlock a:first-child:hover {
  text-shadow: 0.7px 0.7px 1px #fff, -0.7px -0.7px 1px #000, 2px 2px 10px #fff;
  box-shadow: inset 0 0 4px 1px #000, inset 0 6px 7px 0 #008b8b;
}
.projectInfoBlock a:nth-child(2) {
  border-right: #0ff999 solid 1.5px;
  border-left: #09a565 solid 1px;
  color: #0ff;
  box-shadow: inset 0 0 7px 0 #000, 0.1px 0.2px 2px 1.1px #023;
  font-weight: 500;
  text-shadow: 0.5px 0.5px 1.1px #000, -0.9px -0.9px 1.1px #f9f9f9;
}

.projectInfoBlock a:nth-child(2):hover {
  text-shadow: 0.7px 0.7px 1px #fff, -0.7px -0.7px 1px #000, 2px 2px 10px #fff;
  box-shadow: inset 0 0 4px 1px #000, inset 0 6px 7px 0 #008b8b;
}

.inputToggleBox {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.wholeFormBox {
  padding: 10px 20px;
  margin: 5%;
}

.wholeFormBox > div {
  display: flex;
  justify-content: space-between;
}

label {
  text-shadow: 0.5px 0.5px 1px grey, -0.5px -0.5px 1px lightgrey;
  color: black;
}

#contact form {
  display: flex;
  max-width: 880px;
  color: #f5f5f5;
  background: repeating-radial-gradient(
    circle at 7% 92%,
    #333000,
    #32f 33.18px,
    #eee 131.179px,
    purple 143.359px,
    orange 376.162px,
    red 625.141px,
    #fff 688.299px,
    indigo 803.432px
  );
  background-repeat: no-repeat;
  padding: 1rem;
  font-weight: 600;
  font-size: 2rem;
  border-radius: 5px;
  text-shadow: 0 3px 1px #410070;
  color: #edd6fe;
}

#contact form input {
  width: 450px;
  text-align: right;
  margin: 1rem;
  border: solid #639 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #000;
  padding: 2rem;
}

textarea#message {
  width: -webkit-fill-available;
  width: 450px;
  margin: 1rem;
  padding: 3rem 2.4rem;
  box-shadow: -2px -2px 1px 0.24px white, 2px 2px 5px 0.36px black;
  border-radius: 15px;
  background: repeating-radial-gradient(
    circle at 30% 89%,
    #333,
    #333 106.18px,
    #eee 226.179px,
    purple 252.359px,
    orange 271.162px,
    red 295.141px,
    #fff 306.299px,
    indigo 341.432px
  );
  color: white;
  color: purple;
  text-shadow: -0.5px -0.4px 1px white, 0.5px 0.6px 1px black;
}

.submit-captchaBox button {
  margin: 1rem;
  padding: 1rem 2rem;
  width: 70%;
  text-shadow: 0.5px 0.8px 1px white, -0.5px -0.7px 1px black;
  color: white;
  font-size: 3rem;
  border-radius: 8px;
  background: #b6b60000;
  color: grey;
  box-shadow: inset 0px -4px 4px 1px #000, inset 4px 8px 8px 1px orange,
    0.2px 0.1px 1px 1px black, -0.3px -0.3px 1px 1px white;
}

.submit-captchaBox button:hover {
  text-shadow: 0.5px 0.5px 1px #fff, -0.5px -0.7px 1px #000,
    0.1px 0.1px 10px orangered;
  box-shadow: inset 0px 4px 4px 1px #000, inset 4px 8px 8px 1px darkgrey,
    0.2px 0.1px 1px 1px black, -0.3px -0.3px 1px 1px white;
}

@media only screen and (max-width: 880px) {
  .headerImg img {
    max-width: 25%;
    width: 300px;
    padding: 1rem;
    margin: 2rem 1rem;
    border-radius: 115px;
    background: linear-gradient(145deg, #10ffff, #0ed8e6);
    box-shadow: 10px 10px 10px #0bb4bf, -10px -10px 10px #13ffff;
  }
  nav {
    padding: 0.1rem;
    width: fit-content;
    border: solid #0ff 1rem;
    border-radius: 5px;
    box-shadow: 0.12px 0.24px 4px black, -0.12px -0.24px 4px white;
    top: 0.9rem;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  nav a {
    font-size: 3.2rem;
    padding: 1rem;
    text-shadow: 0.5px 0.5px 1.1px #000, -0.9px -0.9px 1.1px #f9f9f9;
    color: #0ff999;
    background: #00ff7f;
    transition: text-shadow 125ms ease;
    border-radius: 5px;
    box-shadow: 0.12px 0.24px 4px black, -0.12px -0.24px 4px white;
  }
  #skills h2 {
    margin: 8rem 0rem -3rem 0rem;
  }
  #skills > p {
    color: darkturquoise;
    padding-right: 1rem;
    margin-right: 2rem;
  }
  #about h2 {
    font-variant: unicase;
    padding: 0px 0 20px 30px;
    text-shadow: 1.12px 1.48px 4px #714505, -1.12px -2.48px 4px #f1d196;
    color: darkorange;
    box-shadow: inset -222px 222px 220px 218px #ff9800;
    font-size: 10rem;
    margin: 0rem -1rem 0rem -1rem;
    border-radius: 0 0 5px 5px;
    max-width: 100vw;
  }
  label {
    max-width: min-content;
  }
  * {
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 600px) {
  .navSkills {
    width: auto;
    max-width: 100%; /* width: 100%; */
  }

  * {
    box-sizing: border-box;
  }

  p {
    max-width: auto;
  }

  section#skills {
    width: auto;
  }

  html {
    width: 100vw;
    max-width: 600px;
  }
  .headerImg {
    flex-direction: column;
  }
  nav {
    box-sizing: border-box;
    border: solid #0ff 1rem;
    border-radius: 5px;
    box-shadow: 0.12px 0.24px 4px black, -0.12px -0.24px 4px white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  nav {
    top: 0px;
    left: 0px;
    max-width: 100%;
    width: fit-content;
    min-width: 98%;
    margin: 0 auto;
    right: 0px;
  }
  nav a {
    width: 100%;
    text-align: center;
    font-size: 3rem;
    padding: 1.2rem;
  }
  .headerImg img {
    max-width: 25%;
    width: 300px;
    padding: 1rem;
    margin: 200px 2rem 0rem auto;
    border-radius: 115px;
    background: linear-gradient(145deg, #10ffff, #0ed8e6);
    box-shadow: 10px 10px 10px #0bb4bf, -10px -10px 10px #13ffff;
  }

  .headerImg > span {
    font-size: 10rem;
    text-align: right;
    position: absolute;
    right: 5%;
    top: 60%;
  }
  section#skills {
    width: 100%;
    font-size: 3.5rem;
    font-weight: 600;
    padding: 3rem 0rem 1rem 1rem;
    font-variant: unicase;
    text-shadow: -0.5px -0.5px 1.1px #000, 0.9px 0.9px 1.1px #f9f9f9;
    margin: -175px 0 3rem 2rem;
  }

  section#skills {
    width: auto;
  }
  #skills > h2 {
    /* width: 100%; */
    font-size: 5.2rem;
    font-weight: 700;
    padding: 0px 0 0 0rem;
    margin: 0% 0% 0% 0%;
  }
  #skills > p {
    width: 370px;
    margin: 0;
  }
  section#about > h2 {
    font-size: 2.4rem;
    margin: auto 0;
  }
  section#contact {
    box-sizing: border-box;
    max-width: 100vw;
    width: 100%;
  }
  #contact form,
  .reachout {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .wholeFormBox {
    padding: 10%;
    margin: 0 auto;
    width: 100vw;
  }

  .wholeFormBox > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  label {
    text-shadow: 0.5px 0.5px 1px grey, -0.5px -0.5px 1px lightgrey;
    color: black;
    max-width: max-content;
    width: 100%;
    font-size: 1.5rem;
    text-align: -webkit-center;
  }
  #contact form input {
    width: 100%;
    margin: 0.5rem;
    border: solid #639 1.6px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #000;
    padding: 2rem;
  }
  textarea#message {
    width: -webkit-fill-available;
    width: 100%;
    margin: 1rem;
    padding: 1rem 2rem;
    box-shadow: -2px -2px 1px 0.24px white, 2px 2px 5px 0.36px black;
    border-radius: 15px;
    background: repeating-radial-gradient(
      circle at 30% 89%,
      #333,
      #333 106.18px,
      #eee 226.179px,
      purple 252.359px,
      orange 271.162px,
      red 295.141px,
      #fff 306.299px,
      indigo 341.432px
    );
    color: purple;
    text-shadow: -0.5px -0.4px 1px white, 0.5px 0.6px 1px black;
  }

  .submit-captchaBox button {
    margin: 1rem;
    width: 110%;
    text-shadow: 0.5px 0.8px 1px white, -0.5px -0.7px 1px black;
    color: white;
    font-size: 3rem;
    border-radius: 8px;
    background: linear-gradient(45deg, firebrick, orangered, transparent);
    color: grey;
    box-shadow: inset 0px -4px 4px 1px #000, inset 4px 8px 8px 1px orange,
      0.2px 0.1px 1px 1px black, -0.3px -0.3px 1px 1px white;
  }
  .navSkills {
    width: auto;
    max-width: 100%; /* width: 100%; */
  }
  * {
    box-sizing: border-box;
  }
  p {
    max-width: auto;
  }
}
@media only screen and (max-width: 490px) {
  .headerImg img {
    max-width: 25%;
    width: 300px;
    padding: 1rem;
    margin: 203px 1rem 3rem auto;
    border-radius: 115px;
    background: linear-gradient(145deg, #10ffff, #0ed8e6);
    box-shadow: 10px 10px 10px #0bb4bf, -10px -10px 10px #13ffff;
  }
  #skills > h2 {
    font-size: 5.2rem;
    font-weight: 700;
    padding: 0px 0 0rem 0rem;
    margin: -7% 0% 7% 0%;
  }

  section#skills {
    width: auto;
    font-size: 3.5rem;
    font-weight: 600;
    padding: 3rem 0rem 1rem 1rem;
    font-variant: unicase;
    text-shadow: -0.5px -0.5px 1.1px #000, 0.9px 0.9px 1.1px #f9f9f9;
    margin: -130px 0 3rem 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .headerImg {
    flex-direction: column;
  }

  .headerImg img {
    max-width: 25%;
    width: 300px;
    padding: 1rem;
    margin: 55% 0% -50% 6%;
    border-radius: 115px;
    background: linear-gradient(145deg, #10ffff, #0ed8e6);
    box-shadow: 10px 10px 10px #0bb4bf, -10px -10px 10px #13ffff;
  }

  section#skills {
    width: 100%;
    font-size: 3.5rem;
    font-weight: 600;
    padding: 0rem 0rem 0rem 1rem;
    font-variant: unicase;
    text-shadow: -0.5px -0.5px 1.1px #000, 0.9px 0.9px 1.1px #f9f9f9;
    margin-top: 0rem;
  }
  #skills > h2 {
    width: 100%;
    font-size: 5.2rem;
    font-weight: 700;
    padding: 0px 0 0 0rem;
    margin: 33% 0% 0% 42%;
  }
  #skills > p {
    margin: 2rem 1rem 2rem 2rem;
  }

  #skills > p {
    margin: 2rem 0rem 2rem 0rem;
    width: auto;
  }

  #skills > h2 {
    width: auto;
  }

  section#skills {
    width: auto;
  }
}
